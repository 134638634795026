import i18n from "i18next";
import { initReactI18next as reactI18nextModule } from "react-i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import * as en from "./locales/en";
import * as he from "./locales/he";
import * as de from "./locales/de";
import * as it from "./locales/it";
import * as es from "./locales/es";
import * as ru from "./locales/ru";
import * as pl from "./locales/pl";
import * as pt_br from "./locales/pt-br";
import * as fr from "./locales/fr";
import * as ja from "./locales/ja";
import * as th from "./locales/th";
// the translations
const resources = {
  en,
  es,
  he,
  ru,
  de,
  it,
  pt_br,
  fr,
  pl,
  ja,
  th,
};

i18n
  .use(reactI18nextModule)
  .use(intervalPlural) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    namespaces: [
      "common",
      "signin",
      "password",
      "errors",
      "docs",
      "custom_message",
    ],
    defaultNS: "common",
    fallbackNS: "common",
    returnEmptyString: false, // // allow an empty value to count as invalid (by default is true)

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
