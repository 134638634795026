function isAuthenticated() {
  return sessionStorage.getItem("auth");
}
function setAuthenticated(user) {
  sessionStorage.setItem("auth", JSON.stringify(user));
}

function deleteAuthenticated() {
  sessionStorage.removeItem("auth");
}
function isAdmin() {
  //return true;
  const user = JSON.parse(sessionStorage.getItem("auth"));
  return user.IsAdmin !== undefined;
}
const auth = {
  isAuthenticated,
  setAuthenticated,
  deleteAuthenticated,
  isAdmin
};

export default auth;
