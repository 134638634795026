import React, { Component } from "react";
import "./small-title.scss";
class SmallTitle extends Component {
  state = {};
  render() {
    return (
      <h1 className="small-title" {...this.props}>
        {this.props.text}
      </h1>
    );
  }
}

export default SmallTitle;
