import React, { Component } from "react";
import "./App.scss";
import "font-awesome/css/font-awesome.min.css";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "utils/history";

import Api from "utils/api";
import { Modules, ReCaptchaSiteKey } from "utils/const";
import { HostName, ContentBaseUrl } from "utils/const";
import SignIn from "./pages/signin/signin";
import SignOut from "./pages/signout/signout";
import PasswordPages from "./pages/password-pages";
import Docs from "./pages/docs/docs";
import { withTranslation } from "react-i18next";
import cssVars from "css-vars-ponyfill";

import { AppDataProvider } from "./Context/AppDataContext";
import NewLogin from "./pages/signin/new-login/new-login";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import i18n from "i18n/index";
import { checkIsIE } from "utils/func";
import Signup from "pages/signup/signup";
const DoCaptcha = (props) => {
  if (props.use === true) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={props.reCaptchaKey}
        useRecaptchaNet={props.useRecaptchaNet}
      >
        {props.children}
      </GoogleReCaptchaProvider>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
};
class App extends Component {
  state = {
    config: {
      ChooseLang: false,
      LMS: false,
      OnBoarding: false,
      PreBoarding: false,
      SMSLogin: false,
      LoginType: 0,
      PasswordRule: null,
      PasswordRuleDesc: "",
      ReCaptchaDotNet: false,
      CaptchaEnabled: true,
    },
    isAdmiLoggedInAsUser: false,
    getInI: false,
    logo: "",
    settings: {
      lang: "",
    },
    OrgId: "",
    user: {
      IsAuth: false,
      TOS: false,
      PRIVACY: false,
      IsAdmin: false,
      LastSeen: null,
      RoleName: null,
      SiteName: null,
      UserId: null,
      Username: null,
      DaysHere: null,
    },
    style: {
      PrimeColor: "#485fe4",
    },
    HasLxpAI: false,
    HasLxp: false,
  };
  setLang = (code, startUp, choose) => {
    code = code.toLowerCase();
    if (choose === undefined || choose === true) {
      if (startUp) {
        var checkUserLang = localStorage.getItem("hi-teach-lms-lang");
        if (checkUserLang) {
          code = checkUserLang;
        }
      }
      localStorage.setItem("hi-teach-lms-lang", code);
    }
    this.setState({
      settings: {
        ...this.state.settings,
        lang: code,
      },
    });
    this.props.i18n.changeLanguage(code);
  };
  setUser = (user) => {
    this.setState({
      user: user,
    });
  };
  setConfig = (config) => {
    this.setState({
      config: config,
    });
  };
  componentDidMount() {
    // loadReCaptcha(ReCaptchaSiteKey);

    Api.post("UserPanel/CheckSAS", { domain: HostName })
      .then((response) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = response;
        if (Success && Payload.LoginInfo != null) {
          this.setState({
            isAdmiLoggedInAsUser: Payload.LoginInfo.IsAdmiLoggedInAsUser,
          });
          this.setUser({
            IsAuth: true,
            TOS: Payload.LoginInfo.TOS,
            PRIVACY: Payload.LoginInfo.PRIVACY,
            IsAdmin: Payload.LoginInfo.IsAdmin,
            LastSeen: Payload.LoginInfo.LastSeen,
            RoleName: Payload.LoginInfo.RoleName,
            SiteName: Payload.LoginInfo.SiteName,
            UserId: Payload.LoginInfo.UserId,
            Username: Payload.LoginInfo.Username,
            DaysHere: Payload.LoginInfo.DaysHere,
            UserType: Payload.LoginInfo.UserType,
          });
        }
        this.setLang(
          Payload.System_Language.toLowerCase(),
          true,
          Payload.Config.ChooseLang
        );
        //this.props.i18n.changeLanguage(Payload.System_Language.toLowerCase());
        //console.log(Payload.Style);
        cssVars({
          variables: {
            primary_color: Payload.Style.BackgroundColor,
            secondary_color: Payload.Style.HeaderColor,
            "bg-image": `url("${ContentBaseUrl}${Payload.Style.HomePagePicture}")`,
            color1: Payload.Style.BackgroundColor,
            "color1-text": Payload.Style.HeaderColor,
            color2: Payload.Style.HeaderColor,
            "color2-text": Payload.Style.BackgroundColor,
            LoginBoxBg: Payload.Style.LoginBoxBg,
          },
        });
        const passwordRules = Payload.Config.PasswordRuleDesc;
        try {
          const passwordRulesJSON = JSON.parse(passwordRules);
          for (let key in passwordRulesJSON) {
            i18n.addResource(
              key,
              "password",
              "passwordRule",
              passwordRulesJSON[key]
            );
            /*i18n.addResourceBundle(key, "Custom", {
            passwordRule: a[key]
          });*/
          }
          //this.setState({ udt: "  " });
        } catch (ex) {}
        const customTranslate = Payload.Config.CustomTranslate;
        try {
          //console.log(customTranslate);
          const customTranslateJSON = JSON.parse(customTranslate);
          //console.log(customTranslateJSON);
          for (let lang in customTranslateJSON) {
            for (let ns in customTranslateJSON[lang]) {
              i18n.addResources(lang, ns, customTranslateJSON[lang][ns]);
            }
          }
          //this.setState({ udt: " " });
        } catch (ex) {}
        this.setState({
          getInI: true,
          config: Payload.Config,
          modules: Payload.Modules,
          RefTo: Payload.LoginInfo == null ? "lms" : Payload.LoginInfo.RefTo,
          settings: {
            ...this.state.settings,
          },
          HasLxpAI: Payload.HasLxpAI,
          HasLxp: Payload.HasLxp,
          logo: ContentBaseUrl + Payload.Style.CompanyLogo,
          OrgId: Payload.OrgId,
        });
      })
      .catch((err) => {
        this.setState({ getInI: true });
      });
  }
  render() {
    const t = this.props.t;
    let customMessage = {};
    //console.log(t("custom_message:color_text"));
    if (t("custom_message:color_text") != "color_text")
      customMessage.color = t("custom_message:color_text");
    if (t("custom_message:bg_text") != "bg_text")
      customMessage.backgroundColor = t("custom_message:bg_text");
    //{ color: "white", backgroundColor: "red" };
    return (
      <AppDataProvider
        value={{
          settings: this.state.settings,
          user: this.state.user,
          style: this.state.style,
          config: this.state.config,
          isAdmiLoggedInAsUser: this.state.isAdmiLoggedInAsUser,
          modules: this.state.modules,
          setUser: this.setUser,
          global: this.state.global,
          setLang: this.setLang,
        }}
      >
        {this.state.getInI && (
          <DoCaptcha
            use={this.state.config.CaptchaEnabled}
            reCaptchaKey={ReCaptchaSiteKey}
            useRecaptchaNet={this.state.config.ReCaptchaDotNet}
          >
            <div className={t("dir") + " App theme dark-theme"}>
              {checkIsIE() && window.innerWidth > 768 && (
                <div className="custom-message" style={customMessage}>
                  {t("custom_message:chrome_text")}
                  <img src={require("./images/chromeicon.png")} />
                </div>
              )}
              {/* <div className={"ltr App theme dark-theme"}> */}
              <Router history={history}>
                <Switch>
                  {/* I added */}
                  <Route path="/new-login" component={NewLogin} />
                  <Route path="/docs" component={Docs} />
                  {(this.state.HasLxpAI || this.state.HasLxp) && (
                    <Route
                      path="/signup/:RoleFamilyId?/:HasLxpAI?"
                      exact
                      render={(props) =>
                        this.state.user.IsAuth === false ? (
                          <Signup
                            {...props}
                            logo={this.state.logo}
                            HasLxpAI={this.state.HasLxpAI}
                            LoginType={this.state.config.LoginType}
                            OrgId={this.state.OrgId}
                          />
                        ) : (
                          <Redirect
                            to={{
                              pathname: "/",
                              state: { from: props.location },
                            }}
                          />
                        )
                      }
                    />
                  )}
                  <Route
                    path="/signin/(otp)?/:otp?"
                    exact
                    render={(props) =>
                      this.state.user.IsAuth === false ? (
                        <SignIn
                          logo={this.state.logo}
                          {...props}
                          HasLxpAI={this.state.HasLxpAI}
                          HasLxp={this.state.HasLxp}
                        />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/",
                            state: { from: props.location },
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    path="/signout"
                    exact
                    render={(props) =>
                      this.state.user.IsAuth === true ? (
                        <SignOut />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/signin",
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    path="/password"
                    render={(props) =>
                      this.state.user.IsAuth === false ? (
                        <PasswordPages {...props} />
                      ) : (
                        <Redirect
                          to={{
                            pathname: "/",
                            state: { from: props.location },
                          }}
                        />
                      )
                    }
                  />
                  <Route
                    render={(props) => {
                      //console.log(this.state.modules);
                      if (this.state.user.IsAuth === true) {
                        //checj i f user or admin

                        /*
                          let url = Modules[this.state.RefTo.Module];

                          window.location.href = url;*/

                        let goTo = "onboarding";
                        if (this.state.user.IsAdmin) goTo = "dashboard";
                        /*else if (
                            this.state.modules.Evaluation &&
                            this.state.user.UserType.DirectManager
                          )
                            goTo = "dashboard";*/ else if (
                          this.state.modules.LMS
                        )
                          goTo = "lms";
                        else if (this.state.modules.OnBoarding)
                          goTo = "onboarding";
                        else if (this.state.modules.LxpAI) goTo = "LxpAI";
                        else if (this.state.modules.RLXP) goTo = "rlxp";
                        else if (this.state.modules.Evaluation)
                          goTo = "evaluation";
                        else if (this.state.modules.PreBoarding)
                          goTo = "preboarding";
                        else if (this.state.modules.Skills) goTo = "skills";

                        const url =
                          window.location.hostname === "skills.hrpluscloud.com"
                            ? "/test"
                            : Modules[goTo];
                        if (this.state.user.TOS && this.state.user.PRIVACY) {
                          window.location.href = url; //+ "/#/admin/dashboard";
                        } else {
                          return (
                            <Redirect
                              to={{
                                pathname: "/docs",
                                seatch: props.location.search,
                                state: {
                                  from: props.location,
                                  goTo: Modules[goTo],
                                },
                              }}
                            />
                          );
                        }
                      } else {
                        return (
                          <Redirect
                            to={{
                              pathname: "/signin",
                              state: { from: props.location },
                            }}
                          />
                        );
                      }
                    }}
                  />
                </Switch>
              </Router>
            </div>
          </DoCaptcha>
        )}
      </AppDataProvider>
    );
  }
}

export default withTranslation()(App);
