const EmailRegEx =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const EmailRegEx = /^(([^<>()[]\.,;:\\\/\s@"]+(.[^<>()[]\.,;:\\\/\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
const AccessPoint = process.env.REACT_APP_ACCESS_POINT;
//const AccessPoint = "10.0.0.131";
//const AccessPoint = "localhost";
const ContentBaseUrl = process.env.REACT_APP_CONTENT_BASE_URL;
const ReCaptchaSiteKey = "6Lflob0UAAAAAL4PSwlY1IY-zNEJUr3YTx0imEL8";
const ReCaptchaV2SiteKey = "6LfmHboaAAAAALykcwXzK7z5y4add5kZIYd7sAU5";

const HostName =
  window.location.hostname === "localhost"
    ? "lms.hi-teach.co.il"
    : window.location.hostname;
const DateFormat = "dd/MM/yyyy";
const LoginBase = process.env.REACT_APP_LOGIN_BASE_URL;

const Modules = {
  lms: process.env.REACT_APP_LMS_URL,
  onboarding: process.env.REACT_APP_ONBOARD_URL,
  preboarding: process.env.REACT_APP_PREBOARDING_URL,
  evaluation: process.env.REACT_APP_EVALUATION_URL,
  rlxp: process.env.REACT_APP_LXP_URL,
  dashboard: process.env.REACT_APP_DASHBOARD_URL,
  skillsTest: process.env.REACT_APP_SKILLS_TEST_URL,
  skills: process.env.REACT_APP_SKILLS_URL,
  LxpAI: process.env.REACT_APP_LXPAI_URL,
};
const Errors = {
  e1: `דוא"ל קיים במערכת`,
  e2: `דוא"ל אינו קיים במערכת`,
  e3: `תאריך אינו תקין`,
  e4: `שם משתמש או סיסמה לא נכונים`,
  e5: `אין הרשאה לפעולה זו`,
  e6: `פעולה נכשלה`,
  e7: `משתמש לא קיים`,
  e50: `אנא מלא את כל השדות`,
  e51: `סיסמאות לא תואמות`,
  e53: `כתובת דואר אלקטרוני לא תקינה`,
  e55: `אנא הזן שם מלא (פרטי ומשפחה)`,
  e56: `אנא בחר אגף`,
  e57: `אנא בחר תפקיד`,
  e58: `מספר עובד צריך להכיל רק מספרים`,
  e59: `אנא בחר תאריך קליטה`,
};
export {
  Errors,
  EmailRegEx,
  ContentBaseUrl,
  DateFormat,
  HostName,
  Modules,
  AccessPoint,
  ReCaptchaSiteKey,
  ReCaptchaV2SiteKey,
};
