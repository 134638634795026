import React, { Component } from "react";
import "./page-title.scss";
class PageTitle extends Component {
  state = {};
  render() {
    return <h1 className="page-title">{this.props.text}</h1>;
  }
}

export default PageTitle;
