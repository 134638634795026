import React, { Component } from "react";
import LoginContainer from "layouts/login-container/login-container.jsx";
import Api from "utils/api";
import { Errors } from "utils/const";
import LoginInput from "components/input.jsx";
import { withTranslation } from "react-i18next";
import SetLang from "components/setLang.jsx";
import AppDataContext from "Context/AppDataContext";
import { Link } from "react-router-dom";
import "./change-password.scss";
import PageTitle from "components/PageTitle/page-title";
import SmallTitle from "components/SmallTitle/small-title";
class ChangePassword extends Component {
  static contextType = AppDataContext;
  state = {
    sent: false,
    password: "",
    confirmPassword: "",
    passRules: {
      numCharsOk: false,
      capitalLetterOk: false,
      smallLetterOk: false,
      digitOk: false,
    },
  };
  render() {
    const t = this.props.t;
    const AppData = this.context;
    return (
      <LoginContainer>
        {!this.state.sent ? (
          <React.Fragment>
            {/* <div className="login-title">
              <h1>{this.props.newuser ? t("set.title") : t("change.title")}</h1>
            </div> */}

            <PageTitle
              text={this.props.newuser ? t("set.title") : t("change.title")}
            />

            {/* <div className="text-color text-center">{t("passwordRule")}</div> */}

            <div className="login-error">{this.state.message}</div>
            <form onSubmit={this.submitLogin}>
              <LoginInput
                style={{ marginTop: "0px" }}
                icon="fa-lock"
                className="password-input"
                name="password"
                type="password"
                placeholder={t("placeholder.password")}
                value={this.state.password}
                onChange={this.change}
              />
              <LoginInput
                icon="fa-check-square"
                name="confirmPassword"
                className="password-input"
                type="password"
                placeholder={t("placeholder.confirm")}
                value={this.state.confirmPassword}
                onChange={this.change}
              />
              {/* NOT TRANSLATED */}
              <div className="password-indicators">
                <strong>{t("rules.passwordMustContain")}:</strong>
                <ul>
                  <li>
                    <input
                      readOnly={this.state.passRules.numCharsOk}
                      disabled={!this.state.passRules.numCharsOk}
                      checked
                      type="checkbox"
                    />
                    {t("rules.8chars")}
                  </li>
                  <li>
                    <input
                      readOnly={this.state.passRules.capitalLetterOk}
                      disabled={!this.state.passRules.capitalLetterOk}
                      checked
                      type="checkbox"
                    />
                    {t("rules.englishCapLetter")}
                  </li>
                  <li>
                    <input
                      readOnly={this.state.passRules.smallLetterOk}
                      disabled={!this.state.passRules.smallLetterOk}
                      checked
                      type="checkbox"
                    />
                    {t("rules.englishSmallLetter")}
                  </li>
                  <li>
                    <input
                      disabled={this.state.passRules.digitOk}
                      disabled={!this.state.passRules.digitOk}
                      checked
                      type="checkbox"
                    />
                    {t("rules.digit")}
                  </li>
                </ul>
              </div>
              {/* end NOT TRANSLATED */}

              <LoginInput
                type="submit"
                DclassName="action-btn btn-block"
                value={
                  this.props.newuser ? t("set.submit") : t("change.submit")
                }
              />
            </form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <div className="login-title">
              {this.props.newuser ? t("set.success") : t("change.success")}
            </div> */}

            <div className="text-center">
              <SmallTitle
                text={
                  this.props.newuser ? t("set.success") : t("change.success")
                }
              />
            </div>
          </React.Fragment>
        )}
        <div className="login-bottom text-center">
          <Link className="login-forgot" to="/">
            {t("back_to_login")}
          </Link>
        </div>
        {/* <SetLang /> */}
      </LoginContainer>
    );
  }

  updatePassRules = (pass) => {
    const numCharsRegex = new RegExp(".{8,}");
    const capitalLetterRegex = new RegExp("(?=.*?[A-Z])");
    const smallLetterRegex = new RegExp("(?=.*?[a-z])");
    const digitRegex = new RegExp("(?=.*?[0-9])");

    this.setState({
      passRules: {
        numCharsOk: pass.match(numCharsRegex),
        capitalLetterOk: pass.match(capitalLetterRegex),
        smallLetterOk: pass.match(smallLetterRegex),
        digitOk: pass.match(digitRegex),
      },
    });
  };

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.updatePassRules(e.target.value);
  };
  submitLogin = (e) => {
    e.preventDefault();
    const AppData = this.context; //PasswordRule;

    const validPass = new RegExp(AppData.config.PasswordRule);
    if (this.state.password.trim() === "") {
      this.setState({
        message: this.props.t(`errors:41`),
      });
      return;
    } else if (!validPass.test(this.state.password)) {
      this.setState({
        message: this.props.t(`errors:42`),
      });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        message: this.props.t(`errors:43`),
      });
      return;
    }
    let send = {
      UserId: this.props.match.params.UserId,
      Token: this.props.match.params.Token,
      NewPassword: this.state.password,
    };
    Api.post("UserPanel/ChangePasswordAction", send)
      .then((response) => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode },
        } = response;
        if (Success) {
          this.setState({
            sent: true,
          });

          // setTimeout(() => {
          //   this.props.history.push("/");
          // }, 5000);
        } else {
          this.setState({
            password: "",
            confirmPassword: "",
            message: this.props.t(`errors:${ErrorCode}`, Message),
          });
        }
      })
      .catch(() => {});
  };
}

export default withTranslation("password")(ChangePassword);
