import React, { Component } from "react";
import { /*Router, Route, Switch,*/ Redirect } from "react-router-dom";
import AppDataContext from "Context/AppDataContext";
import Api from "utils/api";
import { Modules } from "utils/const";

class SignOut extends Component {
  static contextType = AppDataContext;
  state = {
    go: true,
  };
  componentWillMount() {
    //Auth.deleteAuthenticated();
    const AppData = this.context;

    Api.get("UserPanel/SignOut")
      .then((response) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = response;
        if (Success) {
          this.context.setUser({
            IsAuth: false,
            IsAdmin: false,
            LastSeen: null,
            RoleName: null,
            SiteName: null,
            UserId: null,
            Username: null,
            DaysHere: null,
          });
          this.setState({ go: true });
          if (AppData.isAdmiLoggedInAsUser) {
            window.parent.postMessage("", Modules.dashboard);
          }
        }
      })
      .catch((err) => {
        this.setState({ go: true });
      });
  }
  render() {
    return (
      <React.Fragment>
        {this.state.go && false ? <Redirect to="/" /> : null}
      </React.Fragment>
    );
  }
}

export default SignOut;
