import React, { Component } from "react";
import "./auth-modal.scss";
import { withTranslation } from "react-i18next";
import Api from "utils/api";
import {
  Errors,
  Modules,
  ReCaptchaSiteKey,
  ReCaptchaV2SiteKey,
} from "utils/const";
import AppDataContext from "Context/AppDataContext";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import SmallTitle from "components/SmallTitle/small-title";
class AuthModal extends Component {
  static contextType = AppDataContext;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      authCode: "",
      msg: { text: "", color: "" },
      isLoading: false,
      attempts: -1,
      reCaptchaV2: false,
      reCaptchaV2Token: "",
    };
  }
  onChangeRecaptchaV2 = (token) => {
    this.setState({
      reCaptchaV2Token: token,
    });
  };
  render() {
    const t = this.props.t;
    return (
      <div
        className={"auth-modal " + (this.props.modalOn ? "visible" : "hidden")}
      >
        <div className="container">
          <span
            className="exit-btn"
            onClick={() => {
              this.closeModal();
            }}
          >
            &times;
          </span>
          <div className="modal-new-title">{t("enter_auth_code")}</div>

          {/* Error */}
          <div className={"login-error " + this.state.msg.color}>
            {this.state.msg.text}
          </div>
          {/* Loading */}

          {/* <div style={{ minHeight: "40px" }}>
            <div className={this.state.isLoading ? "visible" : "hidden"}>
              <i className="fa fa-2x fa-spinner fa-spin"></i>
            </div>
          </div> */}

          <input
            ref={(e) => (this.authCodeInput = e)}
            type="text"
            type="tel"
            name="otp-code"
            value={this.state.authCode}
            onChange={(e) => this.change(e)}
            className="auth-code-input app-input"
            autoComplete="one-time-code"
            autoFocus={true}
            maxLength="6"
          />

          {this.state.reCaptchaV2 && (
            <ReCAPTCHA
              ref={this.recaptchaRef}
              className="recaptchaWraper"
              sitekey={ReCaptchaV2SiteKey}
              onChange={this.onChangeRecaptchaV2}
            />
          )}
        </div>
      </div>
    );
  }

  componentDidMount = () => {
    this.authCodeInput.autofocus = true;
    // if (this.props.autoFillOTP != undefined) {
    //   this.change({ target: { value: this.props.autoFillOTP } });
    // }

    // if ("OTPCredential" in window) {
    //   navigator.credentials
    //     .get({
    //       otp: {
    //         transport: ["sms"],
    //       },
    //     })
    //     .then((get_otp) => {
    //       if (get_otp != null) {
    //         this.change({ target: { value: get_otp.code } });
    //       }
    //     });
    // }
  };

  closeModal = () => {
    this.setState({ authCode: "", msg: { text: "", color: "" } });
    this.props.closeModal();
    this.authCodeInput.disabled = false;
  };

  change = (e) => {
    this.setState({ authCode: e.target.value });
    if (e.target.value.length === 6) {
      this.checkAuth(e.target.value);
    } else {
      this.setState({ msg: { ...this.state.msg, text: "" }, isLoading: false });
    }
  };
  sendOTP = async (code) => {
    const AppData = this.context;
    const t = this.props.t;
    this.authCodeInput.disabled = true;
    this.setState({ isLoading: true });
    let token = "";
    if (AppData.config.CaptchaEnabled) {
      if (this.state.reCaptchaV2 == false)
        token = await this.props.googleReCaptchaProps.executeRecaptcha("otp");
      else token = this.state.reCaptchaV2Token;
    }
    Api.post("UserPanel/OTP", {
      reCaptchaToken: token,
      reCaptchaV2: this.state.reCaptchaV2,
      UserSMSCode: code,
    })
      .then((response) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = response;
        if (Success) {
          // Auth.setAuthenticated(Payload);\
          //console.log("asd1");

          //////this.props.location.search

          /*
            check if module open, redirect from redirected or send to top level (lms > onboarding)
          */
          // console.log(Payload);
          //alert();
          if (this.props.re != undefined && this.props.re.to != undefined) {
            const url =
              Modules[this.props.re.to] + `/#${this.props.re.redirect}`;
            window.location.href = url;
          } else {
            let goTo = "onboarding";
            if (Payload.IsAdmin) goTo = "dashboard";
            // else if (
            //   Payload.Modules.Evaluation &&
            //   Payload.UserType.DirectManager
            // )
            //   goTo = "dashboard";
            else if (Payload.Modules.LxpAI) goTo = "LxpAI";
            else if (Payload.Modules.OnBoarding) goTo = "onboarding";
            else if (Payload.Modules.LMS) goTo = "lms";
            else if (Payload.Modules.RLXP) goTo = "rlxp";
            else if (Payload.Modules.Evaluation) goTo = "evaluation";
            else if (Payload.Modules.PreBoarding) goTo = "preboarding";

            const url = Modules[goTo];
            window.location.href = url; //+ "/#/admin/dashboard";
          }
          /*
          } else if (Payload.RefTo !== null) {
            //console.log("asd3");
            const url = Modules[Payload.RefTo.Module];
            window.location.href = url; //+ "/#/admin/dashboard";
            //this.props.history.push(`${url}`); ///${Payload.RefTo.url}`);
          } else {
            if (AppData.config.LMS) window.location.href = "/lms";
            //this.props.history.push(`/lms`);
            else window.location.href = "/onboarding";
          }*/
        } else {
          this.authCodeInput.disabled = false;
          if (ErrorCode === "Common.220") {
            this.setState({
              reCaptchaV2: true,
              msg: {
                text: t(`errors:${ErrorCode}`),
                color: "text-danger",
              },
              isLoading: false,
              authCode: "",
            });
            return;
          }
          if (this.state.reCaptchaV2) this.recaptchaRef.current.reset();
          this.setState({
            msg: {
              text: t(`errors:${ErrorCode}`),
              color: "text-danger",
            },
            isLoading: false,
            authCode: "",
          });
          this.focusInput();
        }
      })
      .catch(() => {});
  };
  checkAuth = (code) => {
    this.sendOTP(code);
    return;
    const t = this.props.t;

    setTimeout(() => {
      const secret = "123456";
      if (code === secret) {
        this.setState({
          msg: {
            text: t("errors:auth_modal.correct_auth_code"),
            color: "text-success",
          },
          isLoading: false,
        });
      } else {
        this.authCodeInput.disabled = false;
        this.setState({
          msg: {
            text: t("errors:auth_modal.wrong_auth_code"),
            color: "text-danger",
          },
          isLoading: false,
          authCode: "",
        });
        this.focusInput();
      }
    }, 1000);
  };

  focusInput = () => {
    this.authCodeInput.focus();
  };
}

export default withGoogleReCaptcha(withTranslation("signin")(AuthModal));
