import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./setLang.scss";

import AppDataContext from "Context/AppDataContext";
class SetLang extends Component {
  static contextType = AppDataContext;
  changeLang = (e) => {
    const AppData = this.context;
    AppData.setLang(e.target.value);
  };
  render() {
    const AppData = this.context;
    if (!AppData.config.ChooseLang) return null;
    return (
      <div className="set-lang">
        <select
          onChange={this.changeLang}
          value={AppData.settings.lang}
          className="app-select"
        >
          <option value="he">עברית</option>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="it">Italiano</option>
          <option value="fr">Français</option>
          <option value="de">Deutsch</option>
          <option value="pt_br">Português</option>
          <option value="pl">Polski</option>
          <option value="ru">Русский</option>
          <option value="ja">日本語</option>
          <option value="th">ภาษาไทย</option>
        </select>
        <br />
        <br />
      </div>
    );
  }
}
export default withTranslation()(SetLang);
