import React, { Component } from "react";
import { Link } from "react-router-dom";
import LoginContainer from "layouts/login-container/login-container.jsx";
import Api from "utils/api";
import {
  Errors,
  Modules,
  ReCaptchaSiteKey,
  ReCaptchaV2SiteKey,
} from "utils/const";
import "./signin.scss";
import LoginInput from "components/input.jsx";
import SetLang from "components/setLang.jsx";
import { withTranslation } from "react-i18next";
import QueryString from "query-string";
import AuthModal from "./auth-modal/auth-modal";
import AppDataContext from "Context/AppDataContext";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";
import PageTitle from "components/PageTitle/page-title";
class SignIn extends Component {
  static contextType = AppDataContext;
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      idnum: "",
      username: "",
      password: "",
      reCaptchaToken: "",
      message: "",
      re: null,
      tel: "",
      loginType: "",
      authModalOn: false,
      reCaptchaV2: false,
      reCaptchaV2Token: "",
    };
  }

  refreshCaptcha = () => {
    //this.recaptcha.execute();
    /*this.setState({
      reCaptchaToken: ""
    });*/
  };
  componentDidMount() {
    const AppData = this.context;
    if (AppData.config.LoginType === 0) {
      this.setState({
        loginType: AppData.config.DefaultLoginMethod === "1" ? "email" : "sms",
      });
    } else if (AppData.config.LoginType === 1) {
      this.setState({
        loginType: "email",
      });
    } else {
      this.setState({
        loginType: "sms",
      });
    }
    // this.inteval = setInterval(this.refreshCaptcha, 2 * 60 * 1000);
    const parsedQuery = QueryString.parse(this.props.location.search);
    let msg = "";
    if (parsedQuery.why && parsedQuery.why === "expiredAuth")
      msg = this.props.t(`errors:900`, "נותקת עקב חוסר פעילות");
    this.setState({
      re: parsedQuery,
      message: msg,
    });
    if (this.props.match.params.otp != undefined) {
      this.setState({ authModalOn: true });
    }
  }
  componentWillUnmount() {
    //if (this.inteval !== undefined) clearInterval(this.inteval);
  }
  verifyCallback = (token) => {
    this.setState({
      reCaptchaToken: token,
    });
  };
  onChangeRecaptchaV2 = (token) => {
    this.setState({
      reCaptchaV2Token: token,
    });
  };
  render() {
    const AppData = this.context;
    const { t } = this.props;
    return (
      <React.Fragment>
        <LoginContainer withLogo logo={this.props.logo}>
          {/* <h1 className="login-title">{t("title")}</h1> */}

          <PageTitle text={t("title")} />
          <div
            className="login-error"
            dangerouslySetInnerHTML={{ __html: this.state.message }}
          ></div>
          {/* <ReCaptcha
            sitekey={ReCaptchaSiteKey}
            action="login"
            verifyCallback={this.verifyCallback}
            ref={ref => (this.recaptcha = ref)}
          />
          Login Buttons (With SMS/Email*/}
          {AppData.config.LoginType == 0 && (
            <div className="login-buttons-wrapper">
              <button
                onClick={() => this.toggleLoginForm("email")}
                className={
                  "email-btn " +
                  (this.state.loginType === "email" ? "selected" : "")
                }
              >
                {t("login_with_email")}
              </button>

              <button
                onClick={() => this.toggleLoginForm("sms")}
                className={
                  "sms-btn " +
                  (this.state.loginType === "sms" ? "selected" : "")
                }
              >
                {t("login_with_sms")}
              </button>
            </div>
          )}
          {this.state.loginType === "email" ? (
            <form onSubmit={this.submitLoginWithEmail}>
              <LoginInput
                icon="fa-user"
                name="username"
                placeholder={t("username_placeholder")}
                value={this.state.username}
                onChange={this.change}
                type="text"
              />
              <LoginInput
                key="pass"
                className="password-input"
                icon="fa-lock"
                name="password"
                type="password"
                placeholder={t("password_placeholder")}
                value={this.state.password}
                onChange={this.change}
              />
              {this.state.reCaptchaV2 && (
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  className="recaptchaWraper"
                  sitekey={ReCaptchaV2SiteKey}
                  onChange={this.onChangeRecaptchaV2}
                />
              )}
              <LoginInput
                disabled={false && this.state.reCaptchaToken === ""}
                type="submit"
                DclassName="action-btn btn-block"
                value={t("sign_in")}
              />
              <div className="login-bottom text-center">
                <Link className="login-forgot" to="/password/forgot">
                  {t("forgot_password")}
                </Link>
              </div>
            </form>
          ) : (
            <form onSubmit={this.submitLoginWithSMS}>
              <LoginInput
                icon="fa-user"
                name="idnum"
                placeholder={t("employee_number_placeholder")}
                value={this.state.idnum}
                onChange={this.change}
                type="text"
              />
              <LoginInput
                key="tel"
                icon="fa-phone"
                name="tel"
                type="tel"
                placeholder={t("phone_number_placeholder")}
                maxLength="10"
                value={this.state.tel}
                onChange={this.change}
              />
              {this.state.reCaptchaV2 && (
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  className="recaptchaWraper"
                  sitekey={ReCaptchaV2SiteKey}
                  onChange={this.onChangeRecaptchaV2}
                />
              )}
              <LoginInput
                disabled={false && this.state.reCaptchaToken === ""}
                type="submit"
                DclassName="action-btn btn-block"
                value={t("send_code")}
              />

              <div className="login-bottom"></div>
            </form>
          )}

          {/* {this.context.config.CaptchaEnabled && (
            <div className="grecaptcha-badge-alt">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and
              <a href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply.
            </div>
          )} */}
          <div className="policies-links">
            <Link className="login-forgot" to="/docs/tos">
              {t("common:policies.tos")}
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="login-forgot" to="/docs/privacy">
              {t("common:policies.privacy")}
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link className="login-forgot" to="/docs/cookies">
              {t("common:policies.cookies")}
            </Link>
            &nbsp;&nbsp;&nbsp;
            {this.props && this.props.HasLxpAI && this.props.HasLxp && (
              <Link className="login-forgot" to="/signup?HasLxpAI=true">
                {t("sign_up")}
              </Link>
            )}
            {this.props && this.props.HasLxpAI && !this.props.HasLxp && (
              <Link className="login-forgot" to="/signup?HasLxpAI=true">
                {t("sign_up")}
              </Link>
            )}
            {this.props && !this.props.HasLxpAI && this.props.HasLxp && (
              <Link className="login-forgot" to="/signup">
                {t("sign_up")}
              </Link>
            )}
          </div>
          {this.context.config.CaptchaEnabled && (
            <div className="grecaptcha-badge-alt">
              This site is protected by reCAPTCHA and the Google{" "}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{" "}
              and{" "}
              <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
              apply.
            </div>
          )}
        </LoginContainer>
        {this.state.authModalOn && (
          <AuthModal
            re={this.state.re}
            autoFillOTP={this.props.match.params.otp}
            modalOn={this.state.authModalOn}
            closeModal={() => {
              this.setState({ authModalOn: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
  toggleLoginForm = (loginType) => {
    this.setState({ loginType, message: "" });
  };

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitLoginWithSMS = async (e) => {
    e.preventDefault();

    this.setState({ message: "" });
    const { t } = this.props;
    const phoneLengthError = t("errors:phone_errors.phone_length_error");

    // Error handling
    if (this.state.tel.length !== 10) {
      this.setState({ message: phoneLengthError });
    } else {
      const AppData = this.context;
      let token = "";
      if (AppData.config.CaptchaEnabled) {
        if (this.state.reCaptchaV2 == false)
          token = await this.props.googleReCaptchaProps.executeRecaptcha(
            "SMSLogin"
          );
        else token = this.state.reCaptchaV2Token;
      }
      let send = {
        Username: this.state.idnum,
        PhoneNumber: this.state.tel,
        reCaptchaToken: token,
        reCaptchaV2: this.state.reCaptchaV2,
      };
      Api.post("UserPanel/GetLogin", send)
        .then((response) => {
          const {
            data: { Success, Payload, Message, ErrorCode },
          } = response;
          if (Success) {
            // this.recaptcha.execute();
            this.setState({ authModalOn: true });
            this.setState({
              reCaptchaToken: "",
              username: "",
              password: "",
              tel: "",
              message: "",
            });
          } else {
            //this.recaptcha.execute();
            if (ErrorCode === "Common.220") {
              this.setState({
                reCaptchaToken: "",
                reCaptchaV2: true,
                message: this.props.t(`errors:${ErrorCode}`, Message),
              });
              return;
            }
            if (this.state.reCaptchaV2) this.recaptchaRef.current.reset();
            this.setState({
              reCaptchaToken: "",
              username: "",
              password: "",
              tel: "",
              message: this.props.t(`errors:${ErrorCode}`, Message),
            });
          }
        })
        .catch(() => {});
    }
  };

  submitLoginWithEmail = async (e) => {
    e.preventDefault();
    const AppData = this.context;
    let token = "";
    if (AppData.config.CaptchaEnabled) {
      if (this.state.reCaptchaV2 == false)
        token = await this.props.googleReCaptchaProps.executeRecaptcha(
          "EmailLogin"
        );
      else token = this.state.reCaptchaV2Token;
    }
    let send = {
      Username: this.state.username.trim(),
      Password: this.state.password,
      reCaptchaToken: token,
      reCaptchaV2: this.state.reCaptchaV2,
    };
    Api.post("UserPanel/GetLogin", send)
      .then((response) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = response;
        if (Success) {
          if (Payload.OTP && Payload.OTP.IsOTP) {
            this.setState({ authModalOn: true });
            this.setState({
              reCaptchaToken: "",
              username: "",
              password: "",
              tel: "",
              message: "",
            });
            return;
          }
          // Auth.setAuthenticated(Payload);\
          //console.log("asd1");

          //////this.props.location.search

          /*
            check if module open, redirect from redirected or send to top level (lms > onboarding)
          */
          // console.log(Payload);
          //alert();
          if (this.state.re != undefined && this.state.re.to != undefined) {
            const url = Payload.Modules.SkillsTest
              ? "/test" + `/#${this.state.re.redirect}`
              : Modules[this.state.re.to] + `/#${this.state.re.redirect}`;
            window.location.href = url;
          } else {
            let goTo = "onboarding";
            if (Payload.IsAdmin) goTo = "dashboard";
            // else if (
            //   Payload.Modules.Evaluation &&
            //   Payload.UserType.DirectManager
            // )
            //   goTo = "dashboard";
            else if (Payload.Modules.LxpAI) goTo = "LxpAI";
            else if (Payload.Modules.OnBoarding) goTo = "onboarding";
            else if (Payload.Modules.LMS) goTo = "lms";
            else if (Payload.Modules.RLXP) goTo = "rlxp";
            else if (Payload.Modules.Evaluation) goTo = "evaluation";
            else if (Payload.Modules.PreBoarding) goTo = "preboarding";
            else if (Payload.Modules.SkillsTest) goTo = "skillsTest";
            else if (Payload.Modules.Skills) goTo = "skills";

            const url = Payload.Modules.SkillsTest ? "/test" : Modules[goTo];
            window.location.href = url; //+ "/#/admin/dashboard";
          }
          /* else if (Payload.RefTo !== null) {
            //console.log("asd3");
            const url = Modules[Payload.RefTo.Module];
            window.location.href = url; //+ "/#/admin/dashboard";
            //this.props.history.push(`${url}`); ///${Payload.RefTo.url}`);
          } else {
            if (AppData.config.LMS) window.location.href = "/lms";
            //this.props.history.push(`/lms`);
            else window.location.href = "/onboarding";
          }*/
        } else {
          //this.recaptcha.execute();
          if (ErrorCode === "Common.220") {
            this.setState({
              reCaptchaToken: "",
              reCaptchaV2: true,
              message: this.props.t(`errors:${ErrorCode}`, Message),
            });
            return;
          }
          if (this.state.reCaptchaV2) this.recaptchaRef.current.reset();
          this.setState({
            reCaptchaToken: "",
            username: "",
            tel: "",
            password: "",
            message: this.props.t(`errors:${ErrorCode}`, Message),
          });
        }
      })
      .catch(() => {});
  };
}
export default withGoogleReCaptcha(withTranslation("signin")(SignIn));
