import React from "react";

const AppDataContext = React.createContext({
  user: {},
  style: {},
  config: {},
  usePoints: false,
  reCaptchaToken: null,
  global: {},
  isAdmiLoggedInAsUser: false,
  refreshCaptcha: () => {},
  setUser: () => {},
  setLang: () => {},
});

export const AppDataProvider = AppDataContext.Provider;
export const AppDataConsumer = AppDataContext.Consumer;
export default AppDataContext;
