const checkIEorEdge = function () {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;
  return isIE || isEdge;
};
const checkIsIE = function () {
  return false || !!document.documentMode;
};
const checkIsEdge = function () {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  return !isIE && !!window.StyleMedia;
};
export { checkIEorEdge, checkIsIE, checkIsEdge };
