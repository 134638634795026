import axios from "axios";
import history from "./history";
import Auth from "./auth";
import { AccessPoint } from "./const";

import { checkIEorEdge } from "./func";
const Api = axios.create({
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache" },
  baseURL: AccessPoint + "/api/",
});
const checkAuth = (status) => {
  if (status === 401) {
    Auth.deleteAuthenticated();
    history.push("/login"); ///send with some state
  }
};
Api.interceptors.request.use(
  function (config) {
    if (!checkIEorEdge()) return config;

    let url = config.url;
    let parts = url.split("?");
    config.url =
      `${parts[0]}?ts=${new Date().getTime()}` +
      (parts.length > 1 ? `&${parts[1]}` : "");
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
Api.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error && error.response) checkAuth(error.response.status);
    return Promise.reject(error);
  }
);

export default Api;
