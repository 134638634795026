import React, { useState } from "react";
const LoginInput = (props) => {
  const { icon, className, DclassName, type, noEye, ...input_props } = props;
  const [getType, setType] = useState(type);
  const orgType = type;
  const toggleEye = () => {
    if (getType == "password") setType("text");
    else setType("password");
  };
  return (
    <div className="login-input-box" style={props.style}>
      <div style={{ position: "relative" }} className="">
        {/* {props.icon && (
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={"fa " + props.icon} />
            </span>
          </div>
        )} */}
        {props.isButton ? (
          <button
            {...input_props}
            onClick={props.onClick}
            className={
              DclassName
                ? DclassName
                : "app-input " + (className ? className : "")
            }
          >
            {props.value}
          </button>
        ) : (
          <input
            {...input_props}
            type={getType}
            className={
              DclassName
                ? DclassName
                : "app-input " + (className ? className : "")
            }
          />
        )}
        {!noEye && orgType == "password" && (
          <span
            style={{ fontSize: "20px" }}
            className={
              "eye-icon fa fa-fw" +
              (getType == "password" ? " fa-eye" : " fa-eye-slash")
            }
            onClick={toggleEye}
          ></span>
        )}
      </div>
    </div>
  );
};
export default LoginInput;
