import React, { Component } from "react";
import PageTitle from "components/PageTitle/page-title";
import LoginInput from "components/input";
import LoginContainer from "layouts/login-container/login-container";
import { Link } from "react-router-dom";
import "./signup.scss";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { withTranslation } from "react-i18next";
import Api from "utils/api";
import history from "../../utils/history";
import { HostName, Modules } from "utils/const";
import assetsRole from "../../assets/images/assest-role.png";
import hrplusLpgo from "../../assets/images/hrplus-logo.png";
import selectRole from "../../assets/images/select-role.png";
import startLogo from "../../assets/images/start-logo.gif";
import suitabilityCheck from "../../assets/images/suitability-check.png";
import teaser from "../../assets/images/teaser.png";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      phone: "",
      password: "",
      roleFamily: "",
      blockRoleFamily: false,
      role: "",
      passRules: {
        numCharsOk: false,
        capitalLetterOk: false,
        smallLetterOk: false,
        digitOk: false,
      },
      roleFamilies: [],
      selectedRoleFamilySkills: [],
      dynamicFieldsOptions: [],
      clalSelectName: "",
      clalSelectOptions: [],
      clalSelectedValue: "",
      clalSelectId: "7afc9f94-a7ea-ee11-833d-06c66d64c4e2",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("RoleFamilyId")) {
      const roleFamilyId = queryParams.get("RoleFamilyId");
      Api.post(`Custom/GetDynamicFieldsForSignUp`, {
        OrgId: this.props.OrgId,
        RoleFamilyId: roleFamilyId,
      })
        .then((res) => {
          console.log(res);
          if (res.data.Success) {
            this.setState({
              dynamicFieldsOptions: res.data.Payload.filter(
                (field) => field.Connected
              ).map((field) => ({ ...field, selectedValue: "" })),
            });
          }
          console.log(this.state.dynamicFieldsOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    Api.get(`Custom/GetRoleFamiliesForSignUp?OrgId=${this.props.OrgId}`).then(
      (res) => {
        this.setState({ roleFamilies: res.data.Payload.RoleFamiliesSkills });
        if (queryParams.has("RoleFamilyId")) {
          const roleFamilyId = queryParams.get("RoleFamilyId");
          const tempRoleFamily = res.data.Payload.RoleFamiliesSkills.filter(
            (rf) => rf.RoleFamilyId === roleFamilyId
          );
          const tempRoles =
            tempRoleFamily.length > 0
              ? res.data.Payload.RoleFamiliesSkills.filter(
                  (rf) => rf.RoleFamilyId === roleFamilyId
                )[0].Roles
              : [];

          this.setState({
            roleFamily:
              tempRoleFamily.length > 0 ? tempRoleFamily[0].RoleFamilyName : "",
            blockRoleFamily: true,
            selectedRoleFamilySkills: tempRoles,
          });
        }
      }
    );
  }

  handleSelectedDynamicField = (DF, value) => {
    this.setState({
      dynamicFieldsOptions: this.state.dynamicFieldsOptions.map((field) =>
        field.Id === DF.Id ? { ...field, selectedValue: value } : field
      ),
    });
    console.log(this.state.dynamicFieldsOptions);
  };

  updatePassRules = (pass) => {
    const numCharsRegex = new RegExp(".{8,}");
    const capitalLetterRegex = new RegExp("(?=.*?[A-Z])");
    const smallLetterRegex = new RegExp("(?=.*?[a-z])");
    const digitRegex = new RegExp("(?=.*?[0-9])");

    this.setState({
      passRules: {
        numCharsOk: pass.match(numCharsRegex),
        capitalLetterOk: pass.match(capitalLetterRegex),
        smallLetterOk: pass.match(smallLetterRegex),
        digitOk: pass.match(digitRegex),
      },
    });
  };

  CheckPasswordValidation = (pass) => {
    const numCharsRegex = new RegExp(".{8,}");
    const capitalLetterRegex = new RegExp("(?=.*?[A-Z])");
    const smallLetterRegex = new RegExp("(?=.*?[a-z])");
    const digitRegex = new RegExp("(?=.*?[0-9])");
    return (
      pass.match(numCharsRegex) &&
      pass.match(capitalLetterRegex) &&
      pass.match(smallLetterRegex) &&
      pass.match(digitRegex)
    );
  };

  validate = () => {
    if (!this.state.firstName) {
      alert("Please enter first name");
      return false;
    }
    if (!this.state.lastName) {
      alert("Please enter last name");
      return false;
    }
    if (!this.state.userName) {
      alert("Please enter user name");
      return false;
    }
    if (!this.state.email) {
      alert("Please enter email");
      return false;
    }
    if (!this.state.phone) {
      alert("Please enter phone");
      return false;
    }
    if (!this.state.password) {
      alert("Please enter password");
      return false;
    }
    if (!this.state.roleFamily) {
      alert("Please select role family");
      return false;
    }
    if (!this.state.role) {
      alert("Please select a role");
      return false;
    }
    if (this.state.dynamicFieldsOptions.length > 0) {
      for (let DF in this.state.dynamicFieldsOptions) {
        if (DF.SelectedValue === "") {
          alert(`Please select a value for field ${DF.Name}`);
          return false;
        }
      }
    }
    return true;
  };

  submitSignUp = () => {
    if (
      this.validate() &&
      this.CheckPasswordValidation(this.state.password) &&
      this.props.OrgId
    ) {
      try {
        const isExistDynamicFields = this.state.dynamicFieldsOptions.length > 0;
        let DynamicFields = isExistDynamicFields
          ? this.state.dynamicFieldsOptions.map((df) => ({
              Id: df.Id,
              Name: df.Name,
              Value: df.selectedValue,
            }))
          : [];
        const data = {
          FirstName: this.state.firstName,
          LastName: this.state.lastName,
          UserName: this.state.userName,
          Email: this.state.email,
          Phone: this.state.phone,
          Password: this.state.password,
          OrgId: this.props.OrgId,
          RoleCode: this.state.role,
          DynamicFields,
        };

        Api.post("Custom/SignUp", data).then((response1) => {
          let send = {
            Username: this.state.email.trim(),
            Password: this.state.password,
          };
          if (response1.data && response1.data.Success) {
            Api.post("UserPanel/GetLoginAfterSignUp", send)
              .then((response2) => {
                const {
                  data: { Success, Payload, Message, ErrorCode },
                } = response2;
                if (Success) {
                  // if (this.state.re != undefined && this.state.re.to != undefined) {
                  //   const url = Payload.Modules.SkillsTest
                  //     ? "/test" + `/#${this.state.re.redirect}`
                  //     : Modules[this.state.re.to] + `/#${this.state.re.redirect}`;
                  //   window.location.href = url;
                  // } else {
                  // let goTo = "LxpAI";
                  // if (Payload.IsAdmin) goTo = "dashboard";
                  // else if (Payload.Modules.LxpAI) goTo = "LxpAI";
                  // else if (Payload.Modules.OnBoarding) goTo = "onboarding";
                  // else if (Payload.Modules.LMS) goTo = "lms";
                  // else if (Payload.Modules.RLXP) goTo = "rlxp";
                  // else if (Payload.Modules.Evaluation) goTo = "evaluation";
                  // else if (Payload.Modules.PreBoarding) goTo = "preboarding";
                  // else if (Payload.Modules.SkillsTest) goTo = "skillsTest";
                  // else if (Payload.Modules.Skills) goTo = "skills";
                  const searchParams = new URLSearchParams(
                    this.props.location.search
                  );
                  const hasLxpAI = searchParams.get("HasLxpAI");
                  if (hasLxpAI) {
                    history.push(`/docs?from=signup&HasLxpAI=true`);
                  } else {
                    history.push(`/docs?from=signup`);
                  }
                  // }
                }
              })
              .catch(() => {});
          } else {
            if (response1.data.ErrorCode === "Common.10") {
              alert("Email alredy exists");
            } else if (response1.data.ErrorCode === "Common.410") {
              alert("Phone already exists");
            } else if (response1.data.ErrorCode === "Common.420") {
              alert("User name already exists");
            }
          }
        });
      } catch {}
    }
  };

  render() {
    const t = this.props.t;
    return (
      <div className="signup-page-container">
        <div className="signup-container">
          <div className="upper-info">
            <div className="logo-container">
              <img src={hrplusLpgo} />
            </div>
            <div className="title-quesion">
              <p>{t("signup.ready")}</p>
              <p>{t("signup.potential")}</p>
            </div>
            <div className="small-title-info">
              <p>{t("signup.take")}</p>
              <p>{t("signup.role")}</p>
            </div>
            <div className="teaser-image-container">
              <img src={teaser} />
            </div>
          </div>
          <div className="lower-info">
            <div className="title">{t("signup.platform")}</div>
            <div className="info">
              <p>{t("signup.start")}</p>
              <p>{t("signup.journey")}</p>
              <p>{t("signup.opportunity")}</p>
            </div>
            <div className="steps-info">
              <div className="inner-steps-info">
                <div className="one-step">
                  <img src={assetsRole} className="step-img" />
                  <p>{t("signup.assest")}</p>
                  <p>{t("signup.interview")}</p>
                </div>
                <div className="one-step">
                  <img src={selectRole} className="step-img" />
                  <p>{t("signup.choose")}</p>
                  <p>{t("signup.desired")}</p>
                </div>
                <div className="one-step" style={{ marginTop: "25px" }}>
                  <img src={suitabilityCheck} className="step-img" />
                  <p>{t("signup.check")}</p>
                  <p>{t("signup.for-position")}</p>
                  <p>{t("signup.according")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="login-box">
            <div className="login-box-logo-container">
              <img src={startLogo} className="login-box-logo" />
            </div>
            <div>
              <LoginInput
                icon="fa-user"
                name="FirstName"
                placeholder={t("firstname_placeholder")}
                value={this.state.firstName}
                onChange={(e) => {
                  this.setState({ firstName: e.target.value });
                }}
                type="text"
              />
              <LoginInput
                icon="fa-user"
                name="LastName"
                placeholder={t("lastname_placeholder")}
                value={this.state.lastName}
                onChange={(e) => {
                  this.setState({ lastName: e.target.value });
                }}
                type="text"
              />
              <LoginInput
                icon="fa-user"
                name="UserName"
                placeholder={t("username_placeholder")}
                value={this.state.userName}
                onChange={(e) => {
                  this.setState({ userName: e.target.value });
                }}
                type="text"
              />
              <LoginInput
                icon="fa-user"
                name="Email"
                placeholder={t("email_placeholder")}
                value={this.state.email}
                onChange={(e) => {
                  this.setState({ email: e.target.value });
                }}
                type="text"
              />
              <LoginInput
                icon="fa-user"
                name="Phone"
                placeholder={t("phone_number_placeholder")}
                value={this.state.phone}
                onChange={(e) => {
                  this.setState({ phone: e.target.value });
                }}
                maxLength="10"
                type="text"
              />
              <LoginInput
                icon="fa-user"
                name="Password"
                placeholder={t("password_placeholder")}
                value={this.state.password}
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                  this.updatePassRules(e.target.value);
                }}
                type="password"
              />
              <div className="role-select-container">
                <select
                  disabled={this.state.blockRoleFamily}
                  value={this.state.roleFamily}
                  className="select"
                  onChange={(e) => {
                    const tempRoles =
                      this.state.roleFamilies.filter(
                        (rf) => rf.RoleFamilyName === e.target.value
                      ).length > 0
                        ? this.state.roleFamilies.filter(
                            (rf) => rf.RoleFamilyName === e.target.value
                          )[0].Roles
                        : [];
                    this.setState({
                      roleFamily: e.target.value,
                      selectedRoleFamilySkills: tempRoles,
                    });
                  }}
                >
                  <option value="" selected disabled>
                    {t("select_role_family_placeholder")}
                  </option>
                  {this.state.roleFamilies && this.state.roleFamilies.length > 0
                    ? this.state.roleFamilies.map((rf) => (
                        <option value={rf.RoleFamilyName}>
                          {rf.RoleFamilyName}
                        </option>
                      ))
                    : ""}
                </select>
                {this.state.roleFamily &&
                  this.state.selectedRoleFamilySkills &&
                  this.state.selectedRoleFamilySkills.length > 0 && (
                    <select
                      className="select"
                      onChange={(e) => {
                        this.setState({
                          role: e.target.value,
                        });
                      }}
                    >
                      <option value="" selected disabled>
                        {t("select_role_placeholder")}
                      </option>

                      {this.state.selectedRoleFamilySkills.map((r) => (
                        <option value={r.RoleCode}>{r.RoleName}</option>
                      ))}
                    </select>
                  )}
                {window.location.hostname.split(".")[0] === "clal-ins" &&
                  this.state.clalSelectName &&
                  this.state.clalSelectOptions && (
                    <select
                      className="select"
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.setState({ clalSelectedValue: e.target.value });
                      }}
                    >
                      <option value="" selected disabled>
                        בחירת {this.state.clalSelectName}
                      </option>
                      {this.state.clalSelectOptions.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  )}
                {this.state.dynamicFieldsOptions &&
                  this.state.dynamicFieldsOptions.map((DF) => (
                    <select
                      className="select"
                      onChange={(e) => {
                        console.log(e.target.value);
                        this.handleSelectedDynamicField(DF, e.target.value);
                      }}
                    >
                      <option value="" selected disabled>
                        {t("common:select")} {DF.Name}
                      </option>
                      {DF.Values.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  ))}
              </div>
              <div className="password-indicators">
                <strong>{t("password:rules.passwordMustContain")}:</strong>
                <ul>
                  <li>
                    <input
                      readOnly={this.state.passRules.numCharsOk}
                      disabled={!this.state.passRules.numCharsOk}
                      checked
                      type="checkbox"
                    />
                    {t("password:rules.8chars")}
                  </li>
                  <li>
                    <input
                      readOnly={this.state.passRules.capitalLetterOk}
                      disabled={!this.state.passRules.capitalLetterOk}
                      checked
                      type="checkbox"
                    />
                    {t("password:rules.englishCapLetter")}
                  </li>
                  <li>
                    <input
                      readOnly={this.state.passRules.smallLetterOk}
                      disabled={!this.state.passRules.smallLetterOk}
                      checked
                      type="checkbox"
                    />
                    {t("password:rules.englishSmallLetter")}
                  </li>
                  <li>
                    <input
                      readOnly={this.state.passRules.smallLetterOk}
                      disabled={!this.state.passRules.digitOk}
                      checked
                      type="checkbox"
                    />
                    {t("password:rules.digit")}
                  </li>
                </ul>
              </div>
              <LoginInput
                isButton={true}
                onClick={() => {
                  this.submitSignUp();
                }}
                type="submit"
                DclassName="action-btn btn-block"
                value={t("sign_up")}
              />
            </div>

            <div className="policies-links">
              <Link className="login-forgot" to="/signin">
                {t("sign_in")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("signin")(Signup);
