import SetLang from "components/setLang";
import React, { Component } from "react";
import AppContainer from "../app-container/app-container";
import "./login-container.scss";
class LoginContainer extends Component {
  state = {};
  render() {
    const logo =
      this.props.logo === ""
        ? require("../../assets/images/hiteach_logo.png")
        : window.location.hostname.split(".")[0] === "radware"
        ? require("../../assets/images/Career_Navigator_logo_Black.png")
        : this.props.logo;
    return (
      <AppContainer>
        <div className="login-container">
          <div className="box login-box">
            <SetLang />

            {this.props.withLogo && (
              <div className="login-logo">
                <img src={logo} />
              </div>
            )}
            {this.props.children}
          </div>
        </div>
      </AppContainer>
    );
  }
}
export default LoginContainer;
