import React, { Component, useContext } from "react";
import { Link, Route } from "react-router-dom";
import Api from "utils/api";
import AppContainer from "../../layouts/app-container/app-container";
import { EmailRegEx, HostName, Errors, Modules } from "utils/const";
import "./docs.scss";
import AppDataContext from "Context/AppDataContext";
import history from "utils/history";
import { useTranslation } from "react-i18next";
import QueryString from "query-string";
import SetLang from "components/setLang";
import PageTitle from "components/PageTitle/page-title";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";

const DocIframe = ({ docFileName }) => {
  return (
    <iframe
      className="doc-iframe"
      src={process.env.PUBLIC_URL + "policies/" + docFileName}
    ></iframe>
  );
};
const Privacy_c = ({ signAction, from = "" }) => {
  const AppData = useContext(AppDataContext);
  const { t } = useTranslation("docs");
  const customer = window.location.hostname.split(".")[0];
  return (
    <div className="box doc-overall">
      <div
        className={`doc ${
          AppData.settings.lang === "he" ? "hebrew-privacy" : ""
        }`}
      >
        {AppData.settings.lang !== "he" ? (
          <>
            <PageTitle text="Privacy Policy" />
            <DocIframe docFileName={"privacy_en.html"} />
          </>
        ) : (
          <>
            <PageTitle text="הצהרת פרטיות" />
            <DocIframe
              docFileName={
                customer == "electra-power"
                  ? "privacy_he_electra.html"
                  : "privacy_he.html"
              }
            />
          </>
        )}
      </div>
      <div className="mt-3 text-center">
        {((AppData.user.IsAuth && !AppData.user.PRIVACY) ||
          from === "signup") && (
          <>
            <button
              className="action-btn mb-1"
              onClick={() => {
                signAction();
              }}
            >
              {t("privact-page.agree")}
            </button>
            <br />
          </>
        )}
        {AppData.user.IsAuth && AppData.user.PRIVACY && AppData.user.TOS && (
          <>
            <Link className="action-btn mb-1" to={"/"}>
              {t("home")}
            </Link>
            <br />
            <br />
          </>
        )}
        <Link className="text-color" to={"/docs/tos"}>
          {t("tos")}
        </Link>{" "}
        <br />
        {AppData.user.IsAuth && AppData.user.PRIVACY && AppData.user.TOS && (
          <Link className="text-color" to={"/signout"}>
            {t("signout")}
          </Link>
        )}
      </div>
    </div>
  );
};
const Tos_c = ({ signAction, from = "" }) => {
  const AppData = useContext(AppDataContext);
  const { t } = useTranslation("docs");
  return (
    <div className="box doc-overall">
      <div
        className={`doc ${
          AppData.settings.lang === "he" ? "hebrew-privacy" : ""
        }`}
      >
        {AppData.settings.lang !== "he" ? (
          <>
            <PageTitle text="Terms of Service" />
            <DocIframe docFileName={"tos_en.html"} />
          </>
        ) : (
          <>
            <PageTitle text="תנאי שימוש" />
            <DocIframe docFileName={"tos_he.html"} />
          </>
        )}
      </div>
      <div className="mt-3 text-center">
        {((AppData.user.IsAuth && !AppData.user.TOS) || from === "signup") && (
          <>
            <button
              className="action-btn mb-1"
              onClick={() => {
                signAction();
              }}
            >
              {t("tos-page.agree")}
            </button>
            <br />
          </>
        )}

        {AppData.user.IsAuth && AppData.user.PRIVACY && AppData.user.TOS && (
          <>
            <Link className="action-btn " to={"/"}>
              {t("home")}
            </Link>{" "}
            <br />
            <br />
          </>
        )}
        <Link className="text-color" to={"/docs/privacy"}>
          {t("privacy")}
        </Link>

        <br />
        {AppData.user.IsAuth && AppData.user.PRIVACY && AppData.user.TOS && (
          <Link className="text-color" to={"/signout"}>
            {t("signout")}
          </Link>
        )}
      </div>
    </div>
  );
};
const Cookies = () => {
  const AppData = useContext(AppDataContext);
  const { t } = useTranslation("docs");
  return (
    <>
      <div className="box doc-overall">
        <div className="doc">
          <>
            <PageTitle text="Cookies Policy" />
            <DocIframe docFileName={"cookie_en.html"} />
          </>
        </div>
        <div className="mt-3 text-center">
          <br />
          <Link className="text-color" to={"/"}>
            {t("home")}
          </Link>
        </div>
      </div>
    </>
  );
};
class Docs extends Component {
  static contextType = AppDataContext;
  state = {
    password: "",
    confirmPassword: "",
    sent: false,
    re: null,
    alert: {
      isError: false,
      text: "",
    },
  };
  componentDidMount() {
    const parsedQuery = QueryString.parse(this.props.location.search);

    this.setState({
      re: parsedQuery,
    });
  }
  signAction = (who) => {
    const AppData = this.context;

    Api.post("UserPanel/SetConfirmDocs", {
      TOS: who == "tos",
      PRIVACY: who == "privacy",
    })
      .then((response) => {
        const {
          data: { Success, Payload, Message, ErrorCode },
        } = response;
        if (Success) {
          AppData.setUser({
            ...AppData.user,
            TOS: Payload.TOS,
            PRIVACY: Payload.PRIVACY,
          });
          if (Payload.TOS && Payload.PRIVACY) {
            if (window.location.hostname === "skills") {
              window.location.href = Modules.skillsTest;
              return;
            }

            if (this.state.re != undefined && this.state.re.to != undefined) {
              const url =
                window.location.hostname === "skills.hrpluscloud.com"
                  ? "/test" + `/#${this.state.re.redirect}`
                  : Modules[this.state.re.to] + `/#${this.state.re.redirect}`;
              window.location.href = url;
              return;
            }
            const searchParams = new URLSearchParams(
              this.props.location.search
            );
            const from = searchParams.get("from");
            const hasLxpAI = searchParams.get("HasLxpAI");
            if (from && hasLxpAI) {
              window.location.href = Modules["LxpAI"];
            } else if (from && !hasLxpAI) {
              window.location.href = Modules["rlxp"];
            } else {
              window.location.href = this.props.location.state.goTo;
            } // history.push({
            //   pathname: "/",
            //   search: this.props.location.search,
            // });
          } else if (Payload.TOS) {
            history.push({
              pathname: "/docs/privacy",
              search: this.props.location.search,
            });
          } else if (Payload.PRIVACY) {
            history.push({
              pathname: "/docs/tos",
              search: this.props.location.search,
            });
          }
        }
      })
      .catch(() => {});
  };
  render() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const from = searchParams.get("from");
    const AppData = this.context;
    return (
      <AppContainer>
        {/* <SetLang /> */}
        <div className="docs-container">
          <div className="docs-content">
            <Route
              exact
              path={[`/docs/privacy`, `/docs/`]}
              render={(props) => (
                <Privacy_c
                  signAction={() => {
                    this.signAction("privacy");
                  }}
                  from={from ? from : ""}
                />
              )}
            />
            <Route
              exact
              path={`/docs/tos`}
              render={(props) => (
                <Tos_c
                  signAction={() => {
                    this.signAction("tos");
                  }}
                  from={from ? from : ""}
                />
              )}
            />
            <Route
              exact
              path={`/docs/cookies`}
              render={(props) => <Cookies />}
            />
          </div>
        </div>
      </AppContainer>
    );
  }

  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = (e) => {
    e.preventDefault();
    if (
      this.state.password.trim() === "" ||
      this.state.password.trim().length < 8
    ) {
      this.setState({
        alert: { isError: true, text: window.hiteach_lang.errors.e107 },
      });
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        alert: { isError: true, text: window.hiteach_lang.errors.e109 },
      });
      return;
    }
    Api.post("/api/OnBoard/ChangePasswordAction", {
      userId: this.props.match.params.UserId,
      token: this.props.match.params.Token,
      newPassword: this.state.password,
    })
      .then((res) => {
        this.setState({
          sent: true,
        });
        setTimeout(() => {
          this.props.history.push("/");
        }, 3000);
        //asd
      })
      .catch(() => {});
    // console.log("Submit");
  };
}

export default Docs;
