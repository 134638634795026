import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";
import "./i18n";
import App from "./App";
ReactDOM.render(<App />, document.getElementById("root"));
// custom css
var linkElement = document.createElement("link");
linkElement.setAttribute("rel", "stylesheet");
linkElement.setAttribute("type", "text/css");
linkElement.setAttribute(
  "href",
  process.env.REACT_APP_CONTENT_BASE_URL + "/PersonalStyle/CustomCss"
);
document.getElementsByTagName("head")[0].appendChild(linkElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
