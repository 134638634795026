import React, { Component } from "react";
import "./new-login.scss";

class NewLogin extends Component {
  state = {
    loginType: "email",
  };
  render() {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="login-body">
            {/* <div className="profile-img-container">
              <img
                className="profile-img"
                src={require("../../../assets/images/hiteach_logo_owl.png")}
              ></img>
            </div> */}

            <h2 className="login-title">Log In</h2>

            <div className="switch-btns-wrapper">
              <button
                onClick={() => this.toggleLoginForm("email")}
                className={
                  "switch-btn " +
                  (this.state.loginType === "email" ? "selected" : "")
                }
              >
                <i className="mr-2 fa fa-envelope"></i>
                WITH EMAIL
              </button>
              <button
                onClick={() => this.toggleLoginForm("sms")}
                className={
                  "switch-btn " +
                  (this.state.loginType === "sms" ? "selected" : "")
                }
              >
                <i className="mr-2 fa fa-phone"></i>
                WITH SMS
              </button>
            </div>

            {/* Login with email form */}
            {this.state.loginType === "email" ? (
              <form onSubmit={this.onSubmit}>
                <input
                  className="login-input"
                  placeholder="USERNAME"
                  type="text"
                />
                <input
                  className="login-input"
                  placeholder="PASSWORD"
                  type="password"
                />
                <input
                  className="login-input submit-btn"
                  type="submit"
                  value="Log in"
                ></input>
                <div className="forgot-password-text">
                  Forgot your password?
                </div>
              </form>
            ) : (
              // login with SMS form
              <form onSubmit={this.onSubmit}>
                <input
                  className="login-input"
                  placeholder="USERNAME"
                  type="text"
                />
                <input
                  className="login-input"
                  placeholder="PHONE"
                  type="number"
                />
                <input
                  className="login-input submit-btn"
                  type="submit"
                  value="SEND"
                ></input>
                <div className="forgot-password-text">Trouble logging in?</div>
              </form>
            )}
          </div>
          {/* Login with email form */}
        </div>
      </div>
    );
  }

  toggleLoginForm = (loginType) => {
    this.setState({ loginType });
  };

  onSubmit = (e) => {
    e.preventDefault();
  };
}

export default NewLogin;
