import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LoginContainer from "layouts/login-container/login-container.jsx";
import Api from "utils/api";
import LoginInput from "components/input.jsx";
import { EmailRegEx, HostName, Errors } from "utils/const";
import SetLang from "components/setLang.jsx";
import { Link } from "react-router-dom";
import PageTitle from "components/PageTitle/page-title";
import SmallTitle from "components/SmallTitle/small-title";
class ForgotPassword extends Component {
  state = {
    email: "",
    sent: false,
    message: "",
  };
  render() {
    const t = this.props.t;
    return (
      <LoginContainer>
        {this.state.sent ? (
          <>
            {/* <div className="login-title">
              {t("forgot.sent")}:<br />
              <span style={{ fontSize: "20px", fontWeight: "500" }}>
                {this.state.email}
              </span>
            </div> */}

            <div className="text-center">
              <SmallTitle text={t("forgot.sent")} />
              <SmallTitle text={this.state.email} />
            </div>

            <div className="login-bottom text-center">
              <Link className="login-forgot" to="/">
                {t("back_to_login")}
              </Link>
            </div>
          </>
        ) : (
          <div>
            <PageTitle text={t("forgot.title")} />
            <div
              className="login-error"
              dangerouslySetInnerHTML={{ __html: this.state.message }}
            ></div>
            <form onSubmit={this.submitLogin}>
              <LoginInput
                icon="fa-envelope"
                name="email"
                placeholder={t("forgot.email")}
                value={this.state.email}
                onChange={this.change}
                type="text"
                style={{ marginTop: "0px" }}
              />

              <LoginInput
                type="submit"
                DclassName="btn-block action-btn"
                value={t("forgot.submit")}
              />
              <div className="login-bottom text-center ">
                <Link className="login-forgot" to="/">
                  {t("back_to_login")}
                </Link>
              </div>
            </form>
          </div>
        )}
        {/* <SetLang /> */}
      </LoginContainer>
    );
  }
  change = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  submitLogin = (e) => {
    e.preventDefault();
    if (this.state.email.trim() === "") {
      this.setState({
        message: this.props.t(`errors:41`),
      });
      return;
    } else if (!EmailRegEx.test(this.state.email.trim())) {
      this.setState({
        message: this.props.t(`errors:53`),
      });
      return;
    }
    let send = {
      Domain: HostName,
      By: 1,
      Email: this.state.email.trim(),
    };
    Api.post("UserPanel/ChangePasswordReq", send)
      .then((response) => {
        const {
          data: { Success /*, Payload*/, Message, ErrorCode },
        } = response;
        if (Success) {
          this.setState({
            sent: true,
          });

          // setTimeout(() => {
          //   this.props.history.push("/");
          // }, 5000);
        } else {
          this.setState({
            message: this.props.t(`errors:${ErrorCode}`, Message),
          });
        }
      })
      .catch(() => {});
  };
}

export default withTranslation("password")(ForgotPassword);
